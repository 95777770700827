<template>
  <div class="serviceLeagueForm">
    <div class="serviceLeagueForm-t">
      <img :src="detail.picture" alt="" />
    </div>
    <div class="serviceLeagueForm-b">
      <div class="name">{{ detail.name }}</div>
      <div class="main">
        <div class="title">简介</div>
        <div class="body">{{ detail.description }}</div>
      </div>
      <div class="main">
        <div class="title">联系方式</div>
        <div class="link">
          <div class="item">
            <div class="item-l">姓名：</div>
            <div class="item-r">{{ detail.contactName }}</div>
          </div>
          <div class="item" @click="toAlink">
            <div class="item-l">电话：</div>
            <div class="item-r">{{ detail.contactMobile }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLeagueDetailURL } from "./api.js";
import { gloabalCount } from "@/utils/common";
export default {
  name: "serviceLeagueForm",
  data() {
    return {
      detail: {},
      bannerList: [],
      id: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getTalentDetailList();
    gloabalCount(this.id, 39, 1);
  },
  methods: {
    toAlink(item) {
      window.location.href = `tel://${this.detail.contactMobile}`;
    },
    getTalentDetailList() {
      let params = {
        id: this.id,
      };
      this.$axios
        .get(`${getLeagueDetailURL}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            this.detail = res.data;
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.serviceLeagueForm {
  .serviceLeagueForm-t {
    height: 56.25vw;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .serviceLeagueForm-b {
    .name {
      padding: 36px 52px;
      font-size: 40px;
      font-weight: 600;
      color: #000000;
      line-height: 56px;
    }
    .main {
      .title {
        padding: 0 56px;
        margin-bottom: 14px;
        font-size: 30px;
        font-weight: 400;
        color: #000000;
        line-height: 42px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 8px;
          height: 40px;
          background: #007eff;
          border-radius: 4px;
          left: 24px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .body {
        padding: 0 52px;
        font-size: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 34px;
        margin-bottom: 36px;
      }
      .link {
        padding: 0 52px;
        .item {
          display: flex;
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.createWorkTalentDetail {
}
</style>
